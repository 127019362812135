// The date are "raw" in string for now for example "2020-10-21T10:03:38+00:00"
function formatStringToDate(dateString: string, options: Intl.DateTimeFormatOptions) {
  let currentLanguage = 'fr'
  if (typeof window !== 'undefined') {
    currentLanguage = /\/de/.test(window.location.pathname) ? 'de' : 'fr' // for all requests
  }

  return dateString
    ? new Intl.DateTimeFormat(currentLanguage + '-CH', options).format(new Date(dateString))
    : ''
}

export { formatStringToDate }
