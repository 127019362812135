import React, { useContext } from 'react'
import { createStyles, Typography } from '@material-ui/core'
import { Deal } from 'src/types/Deal'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core/styles'
import { FirebaseContext } from 'src/utils/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import Loading from 'src/components/Molecules/Loading'
import { formatStringToDate } from 'src/utils/libs/date'
import SalesList from 'src/components/Views/SalesManagement/salesList'

type Props = {}
export default function SalesManagementView({}: Props) {
  const classes = useStyles()
  const { firebase, token } = useContext(FirebaseContext)
  const merchantId = token?.claims?.merchantId ?? null

  const [deals, loading] = useCollectionData<Deal>(
    firebase
      .firestore()
      .collection('deals')
      .where(
        'merchant',
        '==',
        merchantId ? firebase.firestore().collection('merchants').doc(merchantId) : null
      ),
    {
      idField: 'id'
    }
  )

  if (loading) {
    return <Loading />
  }

  const headerDeal = (deal: Deal) => {
    return (
      <div className={classes.dealHeader}>
        <Typography>{deal.name}</Typography>
        <Typography>
          Validité d'achat : du{' '}
          {formatStringToDate(new Date(deal.dateTimeStart.seconds * 1000).toISOString(), {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })}{' '}
          au{' '}
          {formatStringToDate(new Date(deal.dateTimeEnd.seconds * 1000).toISOString(), {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })}
        </Typography>
        <Typography>
          Validité d'utilisation : du{' '}
          {formatStringToDate(
            new Date(deal.consumptionDateTimeStart.seconds * 1000).toISOString(),
            {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            }
          )}{' '}
          au{' '}
          {formatStringToDate(new Date(deal.consumptionDateTimeEnd.seconds * 1000).toISOString(), {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })}
        </Typography>
      </div>
    )
  }

  const getFinalQuantity = (deal: Deal) => {
    if (deal.hasVariants) {
      let n = 0
      deal.variants.map((variant) => {
        n += Number(variant.quantity)
      })
      return n
    } else {
      return deal.quantity
    }
  }

  const getFinalQuantityRemaining = (deal: Deal) => {
    if (deal.hasVariants) {
      let n = 0
      deal.variants.map((variant) => {
        n += variant.quantity - variant.quantityRemaining
      })
      return n
    } else {
      return deal.quantity - deal.quantityRemaining
    }
  }

  return (
    <div>
      {deals?.map((deal) => {
        return (
          <div className={classes.dealBlock}>
            <div>{headerDeal(deal)}</div>
            <SalesList deal={deal} />
            <div className={classes.dealFooter}>
              <Typography>Nbre disponible : {getFinalQuantity(deal)}</Typography>
              <Typography>Nbre vendu : {getFinalQuantityRemaining(deal)}</Typography>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dealHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 10,
      backgroundColor: theme.palette.primary.main,
      color: 'black'
    },
    dealBlock: {
      marginBottom: 60
    },
    dealFooter: {
      padding: 10,
      backgroundColor: 'white',
      color: 'black'
    }
  })
)
