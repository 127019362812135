import { Typography } from '@material-ui/core'
import { DataGrid, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid'
import { Merchant, Sale, User } from '@yodo/types'
import firebase from 'firebase/app'
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import Loading from 'src/components/Molecules/Loading'
import { Deal } from 'src/types/Deal'
import { FirebaseContext } from 'src/utils/firebase'
import { formatStringToDate } from 'src/utils/libs/date'

type Props = {
  deal: Deal
}

export default function SalesList({ deal }: Props) {
  const { firebase, token } = useContext(FirebaseContext)
  const [displayedSales, setDisplayedSales] = useState<any | undefined>()
  const [merchant] = useDocumentData<Merchant>(
    firebase.firestore().doc(`merchants/${token?.claims?.merchantId}`),
    {
      idField: 'id'
    }
  )

  const [sales, loading] = useCollectionData<Sale>(
    firebase
      .firestore()
      .collection('sales')
      .where('state', 'in', ['payed', 'used'])
      .where('deal', '==', firebase.firestore().collection('deals').doc(deal.id)),
    {
      idField: 'id'
    }
  )

  const gridWrapperRef = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current
    if (gridDiv) {
      const gridEl: HTMLDivElement = gridDiv.querySelector('div')!
      if (gridEl) {
        gridEl.style.height = ''
      }
    }
  })

  useEffect(() => {
    const transform = async () => {
      if (sales) {
        let displayedSales = await Promise.all(
          sales.map(async (sale) => {
            let customerInfo = ''
            let customerAddressInfo = ''
            const customer = await firebase
              .firestore()
              .collection('users')
              .doc(sale.customer.id)
              .get()

            if (customer.exists) {
              const customerData = customer.data() as User
              customerInfo = `${customerData.displayName} (${customerData.phoneNumber})`
              customerAddressInfo = `${customerData.address}${customerData.address ? ',' : ''} ${
                customerData.postalCode
              } ${customerData.locality} `
            }

            let state = ''
            if (sale.state === 'used') {
              state = formatStringToDate(new Date(sale.updatedAt.seconds * 1000).toISOString(), {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
              })
            } else if (sale.state === 'payed') {
              const now = new Date()
              const consumptionDateTimeEnd = deal.consumptionDateTimeEnd as firebase.firestore.Timestamp
              if (now > new Date(consumptionDateTimeEnd.seconds * 1000)) {
                state = 'Pas venu'
              } else if (now <= new Date(consumptionDateTimeEnd.seconds * 1000)) {
                state = 'Encore en cours - ouvert'
              }
            }

            let variantName = ''
            let currentVariant = null
            if (sale.variantId) {
              const index = deal.variants.findIndex((variant) => variant.id === sale.variantId)
              if (index !== -1) {
                currentVariant = deal.variants[index]
                variantName = `${currentVariant.name} ${currentVariant.description}`
              }
            }

            let reimbursementState = ''
            if (sale.reimbursementDate && sale.priceReimbursed) {
              reimbursementState =
                'CHF ' +
                sale.priceReimbursed * sale.quantity +
                ' | ' +
                formatStringToDate(new Date(sale.reimbursementDate.seconds * 1000).toISOString(), {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                })
            }

            return {
              id: sale.id,
              updatedAt: formatStringToDate(new Date(sale.createdAt.seconds * 1000).toISOString(), {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
              }),
              quantity: sale.quantity,
              customer: customerInfo,
              customerAddress: customerAddressInfo,
              state: state,
              variantId: variantName,
              reimbursementState
            }
          })
        )
        setDisplayedSales(displayedSales)
      }
    }

    if (sales && sales.length) {
      // transform
      transform()
    }
  }, [sales])

  if (loading) {
    return <Loading />
  }

  if (!sales?.length) {
    return null
  }

  const columns = getColumnDefinition({ deal, merchant })

  return (
    <div ref={gridWrapperRef}>
      {/*<GlobalCss />*/}
      <DataGrid
        rows={displayedSales ?? []}
        loading={loading}
        rowHeight={60}
        autoHeight={true}
        autoPageSize
        pageSize={10}
        density="standard"
        columns={columns}
        disableSelectionOnClick
        checkboxSelection={false}
        hideFooterPagination
        hideFooterRowCount
      />
    </div>
  )
}

type Args = {
  deal: Deal
  merchant: Merchant | undefined
}

function getColumnDefinition({ deal, merchant }: Args): GridColDef[] {
  let columnsDefinition = [
    {
      field: 'updatedAt',
      width: 220,
      headerName: "Date d'achat",
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{params.value}</Typography>
      }
    },
    {
      field: 'quantity',
      width: 60,
      headerName: '#',
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{params.value}</Typography>
      }
    },
    {
      field: 'customer',
      width: 320,
      headerName: 'Utilisateur',
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{params.value}</Typography>
      }
    },
    {
      field: 'state',
      width: 320,
      headerName: 'Validation dans mon commerce',
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{params.value}</Typography>
      }
    },
    {
      field: 'reimbursementState',
      width: 320,
      headerName: 'Remboursement',
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{params.value}</Typography>
      }
    }
  ]

  if (merchant?.showClientPersonalData) {
    columnsDefinition.splice(3, 0, {
      field: 'customerAddress',
      width: 320,
      headerName: 'Adresse',
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{params.value}</Typography>
      }
    })
  }

  if (deal.hasVariants) {
    columnsDefinition.splice(1, 0, {
      field: 'variantId',
      width: 150,
      headerName: 'Variantes',
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{params.value}</Typography>
      }
    })
  }

  return columnsDefinition
}
