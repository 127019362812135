import React, { ReactElement } from 'react'
import TopBar from 'src/components/Molecules/TopBar'
import { Container, Typography } from '@material-ui/core'
import AppFooter from 'src/components/Molecules/Footer'
import commonStyles from 'src/styles/commonStyles'
import Layout from 'src/components/Layout'
import PrivateRoute from 'src/components/PrivateRoute'
import Page from 'src/components/Page'
import SalesManagementView from 'src/components/Views/SalesManagement'

export default function SalesManagementPage(): ReactElement {
  const commonClasses = commonStyles()

  return (
    <Layout>
      <Page>
        <PrivateRoute path="/login">
          <TopBar />
          <Container component="main" className={commonClasses.mainContentContainer}>
            <Typography variant={'h1'}>Suivi des ventes</Typography>
            <SalesManagementView />
          </Container>
          <AppFooter />
        </PrivateRoute>
      </Page>
    </Layout>
  )
}
